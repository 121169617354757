var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "public-header--wrapper" }, [
      _c(
        "div",
        { staticClass: "navigation container" },
        [
          _c(
            "el-row",
            {
              staticClass: "toolbar flex-w",
              attrs: {
                type: "flex",
                gutter: 20,
                justify: "space-between",
                align: "middle",
              },
            },
            [
              _c("el-col", [
                _c(
                  "div",
                  { staticClass: "logo" },
                  [
                    _c("Icon", {
                      attrs: {
                        name: "LogoPortTranzit",
                        width: "160px",
                        height: "auto",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "register-of-payments container" },
      [
        _c(
          "el-row",
          {
            staticClass: "toolbar flex-w",
            attrs: {
              type: "flex",
              gutter: 20,
              justify: "space-between",
              align: "middle",
            },
          },
          [
            _c("el-col", [
              _c("h1", { staticClass: "payment-title" }, [
                _vm._v("Как оплатить таймслоты:"),
              ]),
              _c("p", { staticClass: "payment-text" }, [
                _vm._v(
                  " За бронирование таймслотов взимается плата – 150 рублей за один таймслот. "
                ),
              ]),
              _c("p", { staticClass: "payment-text" }, [
                _vm._v(
                  " При выборе терминала, на экране отображается, сколько у вас доступно таймслотов, если на терминале взимается плата за таймслоты. "
                ),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          {
            staticClass: "toolbar flex-w",
            attrs: {
              type: "flex",
              gutter: 20,
              justify: "space-between",
              align: "middle",
            },
          },
          [
            _c("el-col", [
              _c("div", { staticClass: "payment-image" }, [
                _c("img", {
                  staticClass: "payment-image",
                  attrs: { src: require("@/assets/img/payment/pic1.png") },
                }),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          {
            staticClass: "toolbar flex-w",
            attrs: {
              type: "flex",
              gutter: 20,
              justify: "space-between",
              align: "middle",
            },
          },
          [
            _c("el-col", [
              _c("p", { staticClass: "payment-text bold red" }, [
                _vm._v(
                  " Забронировать больше тайм слотов, чем у вас на счете вы не можете. "
                ),
              ]),
              _c("p", { staticClass: "payment-text" }, [
                _vm._v(
                  " У вас есть возможность взять до 3 таймслотов в отрицательный баланс и оплатить их позже. Ваш баланс доступных таймслотов не может быть меньше значения - 3. "
                ),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "el-row",
          {
            staticClass: "toolbar flex-w",
            attrs: {
              type: "flex",
              gutter: 20,
              justify: "space-between",
              align: "middle",
            },
          },
          [
            _c("el-col", [
              _c("h2", { staticClass: "payment-title" }, [
                _vm._v("Как оплачивать тайм слоты"),
              ]),
              _c("p", { staticClass: "payment-text" }, [
                _vm._v(
                  " Если доступных таймслотов вам не хватает, вам необходимо пополнить баланс на необходимое количество. Это можно сделать двумя способами: "
                ),
              ]),
              _c("div", { staticClass: "payment-image" }, [
                _c("img", {
                  staticClass: "payment-image",
                  attrs: { src: require("@/assets/img/payment/pic2.png") },
                }),
              ]),
              _c("p", { staticClass: "payment-text bold" }, [
                _vm._v("1) Выставить счет на организацию:"),
              ]),
              _c("div", [
                _c("ul", [
                  _c("li", { staticClass: "payment-text" }, [
                    _vm._v("Нужно нажать “пополнить счет”"),
                  ]),
                  _c("li", { staticClass: "payment-text" }, [
                    _vm._v("Выбрать “Счет на компанию”"),
                  ]),
                  _c("li", { staticClass: "payment-text" }, [
                    _vm._v("Заполнить все поля"),
                  ]),
                  _c("li", { staticClass: "payment-text" }, [
                    _vm._v(
                      " Счет на оплату генерируется автоматически, после заполнения всех полей и нажатия на кнопку “Сформировать счет” "
                    ),
                  ]),
                  _c("li", { staticClass: "payment-text bold" }, [
                    _vm._v(
                      " После оплаты счета , необходимо прислать документ об оплате на электронную почту "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "payment-mail",
                        attrs: { href: "mailto:invoice.iq@port-tranzit.ru" },
                      },
                      [_vm._v(" invoice.iq@port-tranzit.ru ")]
                    ),
                  ]),
                  _c("li", { staticClass: "payment-text bold" }, [
                    _vm._v(
                      " В теме письма указать номер телефона указанный в профиле "
                    ),
                  ]),
                  _c("li", { staticClass: "payment-text" }, [
                    _vm._v(
                      " После чего ,вам в течении дня будут начислены тайм слоты "
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "payment-image" }, [
                _c("img", {
                  staticClass: "payment-image",
                  attrs: { src: require("@/assets/img/payment/pic3.png") },
                }),
              ]),
              _c("p", { staticClass: "payment-text bold" }, [
                _vm._v("2) Пополнить онлайн:"),
              ]),
              _c("div", [
                _c("ul", [
                  _c("li", { staticClass: "payment-text" }, [
                    _vm._v("Нажать пополнить счет"),
                  ]),
                  _c("li", { staticClass: "payment-text" }, [
                    _vm._v("Выбрать “Пополнить онлайн”"),
                  ]),
                  _c("li", { staticClass: "payment-text" }, [
                    _vm._v("Указать нужное количество таймслотов"),
                  ]),
                  _c("li", { staticClass: "payment-text" }, [
                    _vm._v("Нажать “Перейти к оплате”"),
                  ]),
                  _c("li", { staticClass: "payment-text" }, [
                    _vm._v("Выбрать необходимый способ оплаты"),
                  ]),
                  _c("li", { staticClass: "payment-text" }, [
                    _vm._v("Оплатить"),
                  ]),
                  _c("li", { staticClass: "payment-text" }, [
                    _vm._v(
                      " После оплаты вам начислятся приобретенные тайм слоты "
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "payment-image" }, [
                _c("img", {
                  staticClass: "payment-image",
                  attrs: { src: require("@/assets/img/payment/pic4.png") },
                }),
              ]),
              _c("div", { staticClass: "payment-image" }, [
                _c("img", {
                  staticClass: "payment-image",
                  attrs: { src: require("@/assets/img/payment/pic5.png") },
                }),
              ]),
              _c("p", { staticClass: "payment-text bold" }, [
                _vm._v(
                  " По всем вопросам обращайтесь в службу технической поддержки по телефону "
                ),
                _c(
                  "a",
                  {
                    staticClass: "payment-mail",
                    attrs: { href: "tel:88002507531" },
                  },
                  [_vm._v(" 8 800 250 75 31 ")]
                ),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <div class="public-header--wrapper">
      <div class="navigation container">
        <el-row
          type="flex"
          :gutter="20"
          justify="space-between"
          align="middle"
          class="toolbar flex-w"
        >
          <el-col>
            <div class="logo">
              <Icon name="LogoPortTranzit" width="160px" height="auto" />
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="register-of-payments container">
      <el-row
        type="flex"
        :gutter="20"
        justify="space-between"
        align="middle"
        class="toolbar flex-w"
      >
        <el-col>
          <h1 class="payment-title">Как оплатить таймслоты:</h1>
          <p class="payment-text">
            За бронирование таймслотов взимается плата – 150 рублей за один
            таймслот.
          </p>

          <p class="payment-text">
            При выборе терминала, на экране отображается, сколько у вас доступно
            таймслотов, если на терминале взимается плата за таймслоты.
          </p>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        :gutter="20"
        justify="space-between"
        align="middle"
        class="toolbar flex-w"
      >
        <el-col>
          <div class="payment-image">
            <img src="@/assets/img/payment/pic1.png" class="payment-image" />
          </div>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        :gutter="20"
        justify="space-between"
        align="middle"
        class="toolbar flex-w"
      >
        <el-col>
          <p class="payment-text bold red">
            Забронировать больше тайм слотов, чем у вас на счете вы не можете.
          </p>

          <p class="payment-text">
            У вас есть возможность взять до 3 таймслотов в отрицательный баланс
            и оплатить их позже. Ваш баланс доступных таймслотов не может быть
            меньше значения - 3.
          </p>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        :gutter="20"
        justify="space-between"
        align="middle"
        class="toolbar flex-w"
      >
        <el-col>
          <h2 class="payment-title">Как оплачивать тайм слоты</h2>

          <p class="payment-text">
            Если доступных таймслотов вам не хватает, вам необходимо пополнить
            баланс на необходимое количество. Это можно сделать двумя способами:
          </p>
          <div class="payment-image">
            <img src="@/assets/img/payment/pic2.png" class="payment-image" />
          </div>
          <p class="payment-text bold">1) Выставить счет на организацию:</p>
          <div>
            <ul>
              <li class="payment-text">Нужно нажать “пополнить счет”</li>
              <li class="payment-text">Выбрать “Счет на компанию”</li>
              <li class="payment-text">Заполнить все поля</li>
              <li class="payment-text">
                Счет на оплату генерируется автоматически, после заполнения всех
                полей и нажатия на кнопку “Сформировать счет”
              </li>
              <li class="payment-text bold">
                После оплаты счета , необходимо прислать документ об оплате на
                электронную почту
                <a href="mailto:invoice.iq@port-tranzit.ru" class="payment-mail">
                  invoice.iq@port-tranzit.ru
                </a>
              </li>
              <li class="payment-text bold">
                В теме письма указать номер телефона указанный в профиле
              </li>
              <li class="payment-text">
                После чего ,вам в течении дня будут начислены тайм слоты
              </li>
            </ul>
          </div>
          <div class="payment-image">
            <img src="@/assets/img/payment/pic3.png" class="payment-image" />
          </div>
          <p class="payment-text bold">2) Пополнить онлайн:</p>
          <div>
            <ul>
              <li class="payment-text">Нажать пополнить счет</li>
              <li class="payment-text">Выбрать “Пополнить онлайн”</li>
              <li class="payment-text">Указать нужное количество таймслотов</li>
              <li class="payment-text">Нажать “Перейти к оплате”</li>
              <li class="payment-text">Выбрать необходимый способ оплаты</li>
              <li class="payment-text">Оплатить</li>
              <li class="payment-text">
                После оплаты вам начислятся приобретенные тайм слоты
              </li>
            </ul>
          </div>
          <div class="payment-image">
            <img src="@/assets/img/payment/pic4.png" class="payment-image" />
          </div>
          <div class="payment-image">
            <img src="@/assets/img/payment/pic5.png" class="payment-image" />
          </div>
          <p class="payment-text bold">
            По всем вопросам обращайтесь в службу технической поддержки по
            телефону
            <a class="payment-mail" href="tel:88002507531"> 8 800 250 75 31 </a>
          </p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Icon from '../../UI/icon/Icon'

export default {
  name: 'PaymentDoc',
  components: { Icon },
}
</script>

<style lang="sass">
$fontColor: #333
.payment-phone-link
  text-decoration: none
  color: $color-blue-light
.red
  color: red!important
.payment-mail
  color: $color-blue-light
  font-size: 24px
  text-decoration: none
.payment-title
  margin-bottom: 12px
.bold
  font-weight: bold
  margin: 8px 0px
.payment-text
  margin: 8px 0px
  color: $fontColor
  font-size: 18px
  text-transform: none
  @media (max-width: 1024px)
    width: 100%
.payment-image
  margin: 20px auto
  text-align: center
  width: 70%
  @media (max-width: 1024px)
    width: 100%
.green
  //color: #45D745!important
  ::after
    padding-left: 16px
    content: url('../../assets/icons/arrow-up-green.svg')
.red
  //color: red!important
  ::after
    padding-left: 16px
    content:  url('../../assets/icons/arrow-down-red.svg')
.my-fancy-class
  color: $color-blue-light
  font-weight: bold
  font-size: 18px
.text-head-left
  text-align: left
  color: #909399
  text-transform: uppercase
  font-size: 14px
.text-left
  text-align: left
.emty-table
  text-align: center
  color: $color-blue-light
.public-header--wrapper
  display: flex
  justify-content: space-between
  align-items: center
  background: $color-blue-light
  color: white
  font-size: 16px
  font-weight: bold
  .logo
    font-size: 25px
    line-height: 60px
    font-weight: 900
    text-transform: uppercase
    color: #fff
    & > span
      color: #333
  .navigation
    padding-bottom: 4px!important
    padding-top: 4px!important
.select-container
  display: flex
  justify-content: flex-start
  align-items: center
  background: $color-blue-light
  color: white
  font-size: 16px
  font-weight: bold
  el-select
    height: 40px!important
    width: 50%
  input
    height: 40px!important
  .select-label
    margin-right: 8px
    width: 50%!important

.tableHeader
  color: $color-blue-light
  text-align: left
  font-size: 16px
  padding: 0
  font-weight: bold
  text-transform: uppercase
  text-align: center
  padding: 18px
  border: 1px solid #EBEEF5
  background: #F5F7FA
  margin-bottom: -1px

.terminal-select
  display: flex
  align-items: center
.terminal-select__label
  color: $fontColor
  margin-right: 8px
  text-align: left
  font-size: 14px
.terminal-select__select
  min-width: 300px
.register-of-payments
  height: 80vh
  overflow-y: scroll
  margin-top: 30px
  font-size: 14px
  color: #333
  .btn-group
    display: flex
    align-items: center
    justify-content: flex-end
    .btn
      margin-right: 25px
  .toolbar
    margin-bottom: 20px
    color: $fontColor
  .fs-10
    color: $color-blue-light
  .registry-table.el-table
    border-bottom: 1px solid #EBEEF5 !important
    border-right: 1px solid #EBEEF5 !important
    .el-table__placeholder, .el-table__indent
      display: none
    td, th
      padding: 0
      border-top: 1px solid #EBEEF5 !important
      border-left: 1px solid #EBEEF5 !important
    .cell
      font-size: 14px
      line-height: 1
      color: $fontColor
      padding: 15px
      text-align: center
    td .cell
      white-space: nowrap
    .fs-10
      font-size: 14px
    .group-title .cell
      text-align: left
      font-weight: bold
      padding-left: 20px
      .el-table__header
        color: $fontColor !important
      .el-table__expand-icon
        display: none
</style>
